interface AssetRiskScoreProps extends React.HTMLAttributes<HTMLElement> {
    score: string
}

export const AssetRiskScore = ({score}: AssetRiskScoreProps) => {
      const numericScore = parseFloat(score)

      switch(true) {
        case numericScore === -1:
          return (<span>Not available</span>)
        case isNaN(numericScore):
          return (<span>Loading...</span>)
        default:
          return (<span>{Math.round(numericScore)} / 100</span>)
      }
}