import React from 'react'
import { SearchBox } from '@mapbox/search-js-react';

export const SearchBar = ({accessToken, map, setAssetInFocus, assetInFocus, setFocusedOnAsset}) => {
      const [value, setValue] = React.useState('')

      const options = {
        limit: '5',
        types: new Set(['postcode', 'street', 'address']),
        poi_category: ''
      }

      const theme = {
        // https://docs.mapbox.com/mapbox-search-js/api/web/theming/#themevariables#minwidth
        variables: {
          colorPrimary: "#f1f5f9",
          colorBackground: "#f1f5f9",
        }
      }

      const handleRetrieve = (e) => {
        console.log(e)
        console.log(e.features[0])
        const features = e.features[0]
        // define lon & lat 
        const coordinates = features.geometry.coordinates
        const lon = coordinates[0].toString()
        const lat = coordinates[1].toString()
        
        const context = features.properties.context
        let country, place, postcode, street, number

        const updateAsset = (parent, nestedKey) => {
          // set location variable (should be prop)
          if(context[parent] !== undefined){
            return context[parent][nestedKey]
          } else {
            return ''
          }
        }
        
        const name = ''
        const riskScore = ''
        const UAID = ''
        country = updateAsset('country', 'name')
        place = updateAsset('place', 'name')
        postcode = updateAsset('postcode', 'name')
        street  = updateAsset('street', 'name')
        number = updateAsset('address', 'address_number')

        setFocusedOnAsset(true)

        setAssetInFocus({
          ...assetInFocus,
          UAID: UAID,
          name: name, 
          lon: lon,
          lat: lat,
          country: country,
          place: place,
          postcode: postcode,
          street: street,
          number: number,
          riskScore: riskScore
        })
      }

      return (
        <SearchBox theme={theme} placeholder={"Search Address"} options={options} value={value} map={map.current} onRetrieve={(e) => handleRetrieve(e)} accessToken={accessToken}/>
      )
}