// import modules
import { useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { signOut, connectAuthEmulator, getAuth } from 'firebase/auth'

// import views
import Overview from '@/views/auth/overview'
import Assets from '@/views/auth/assets'

// import components
import { NavList } from "@/components/custom/sidebar/navlist"
import { Header } from "@/components/custom/sidebar/header"
import { Cog6ToothIcon, HomeIcon } from '@heroicons/react/24/outline'
import { Button } from '@/components/ui/button'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import { FolderIcon } from '@heroicons/react/24/outline'

const topSidebarNavItems = [
    // navbar item hrefs are following after /app/{href}
    {
        title: "Risk Map",
        href: "",
        icon: (<HomeIcon/>)
    },
    {
        title: "Assets",
        href: "assets/",
        icon: (<FolderIcon/>)
    }
]

const bottomSidebarNavItems = [
    {
        title: "Settings",
        href: "settings/",
        icon: (<Cog6ToothIcon/>)
    }
]

const logOut = async () => {
    const auth = getAuth()
    await signOut(auth)
}

const Portal = () => {
    const [navbarOpen, setNavbarOpen] = useState<boolean>(false)

    return(
        <div className="flex min-h-full flex-row">
            <aside className={`${navbarOpen ? 'w-44' : 'w-8'} min-w-fit pt-2 px-3 bg-background flex flex-col justify-between`}>
                <div>
                    <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
                    <NavList navbarOpen={navbarOpen} items={topSidebarNavItems} />
                </div>
                <div className='pb-5'>
                    {/* <NavList navbarOpen={navbarOpen} items={bottomSidebarNavItems} /> */}
                    <Button className="mx-2 p-4 mb-2 hover:bg-background hover:border" onClick={() => logOut()} variant="secondary">{navbarOpen ? "Log Out" : <ArrowLeftOnRectangleIcon className="w-5"/>}</Button>
                </div>
            </aside>
            <div className="min-h-full grow">
                <Routes>
                    {/* these routes are children and embedded in /app/* defined by routes in the app.tsx */}
                    <Route element={<Overview navbarOpen={navbarOpen}/>} path="/" />
                    <Route element={<Assets />} path="/assets" />
                </Routes>
            </div>
        </div>
    )
}

export default Portal