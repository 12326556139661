import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useState } from 'react';
import app from './firebase-config'

// import views
import Portal from './views/auth';
import Login from './views/authless/login';
import CreateAccount from './views/authless/createaccount';
import Home from './views/authless/home';


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const auth = getAuth(app);
  onAuthStateChanged(auth, (user) => {
      if (user) {
          setIsAuthenticated(true)
      } else {
          setIsAuthenticated(false)
      }
  });

  return (
      <BrowserRouter>
        <Routes>
          <Route element={<Home/>} path="/" />
          <Route element={<Login/>} path="/login/" />
          <Route element={<CreateAccount/>} path="/create-account/" />
          <Route 
            element={
              <PrivateRoute auth={{ isAuthenticated: isAuthenticated}}>
                <Portal/>
              </PrivateRoute>
            } 
            path="/app/*" />
        </Routes>
      </BrowserRouter>
  );
}

export default App;

//@ts-ignore
const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  return isAuthenticated ? children : <Navigate to="/" />;
};


