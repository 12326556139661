import { getAssets } from "@/actions/assets/getasset"
import { useEffect, useState } from "react"

import { assetInterface } from "@/interfaces";

interface AssetListProps {
    focusedOnAsset: boolean,
    setFocusedOnAsset: Function,
    assetInFocus: assetInterface,
    setAssetInFocus: Function
}

export const AssetSummary = ({focusedOnAsset, setFocusedOnAsset, assetInFocus, setAssetInFocus, ...props}: AssetListProps) => {
    const [assets, setAssets] = useState<Array<assetInterface>>([])

    const updateAssets = () => {
        getAssets().then((assets) => {
            console.log(assets)
            if (assets) {
                const arr = assets.map((asset) => {
                    const data = asset.data()
                    return {
                        UAID: data.uaid,
                        name: data.name,
                        lon: data.lon,
                        lat: data.lat,
                        riskScore: data.riskScore,
                        country: data.country,
                        place: data.place,
                        postcode: data.postcode,
                        street: data.street,
                        number: data.number,
                    }
                })
                setAssets(arr)
            }
        })
    }

    useEffect(() => {
        updateAssets()

        const intervalId = setInterval (() => {
            updateAssets()
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    const handleAssetClicked = async (asset: assetInterface) => {
    
        // latest asset
        await setAssetInFocus({
            ...assetInFocus,
            UAID: asset.UAID,
            name: asset.name, 
            lon: asset.lon,
            lat: asset.lat,
            country: asset.country,
            place: asset.place,
            postcode: asset.postcode,
            street: asset.street,
            number: asset.number,
            riskScore: asset.riskScore
          })
        
          setFocusedOnAsset(true)
    }

    
    return (
        <ul className="list-none divide-y">
            {assets.map((asset) => (
                <li onClick={() => handleAssetClicked(asset)} className="cursor-pointer block px-2 py-3 w-full hover:bg-white flex flex-row justify-between">
                    <div>
                        <p className="text-xs font-bold">Asset in {asset.place ? asset.place : `Unknown Place`}</p>
                        <p className="text-xs">{asset.postcode} {asset.country}</p>
                    </div>
                </li>
            ))}
        </ul>
    )
}