import { getAssets } from "@/actions/assets/getasset"
import { removeAsset } from "@/actions/assets/removeasset"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/components/ui/table"
import { MoreHorizontal } from "lucide-react"
import { useEffect, useState } from "react"
import { AssetRiskScore } from "@/components/custom/assetriskscore" 
import { assetInterface } from "@/interfaces"
import { AssetRiskCategory } from "../assetriskcategory"
import { AssetRiskFrequency } from "../assetriskfrequency"

export const AssetList = () => {
    const [assets, setAssets] = useState<Array<assetInterface>>([])

    const updateAssets = () => {
        getAssets().then((assets) => {
            console.log(assets)
            if (assets) {
                const arr = assets.map((asset) => {
                    const data = asset.data()
                    return {
                        UAID: data.uaid,
                        name: data.name,
                        lon: data.lon,
                        lat: data.lat,
                        country: data.country,
                        place: data.place,
                        postcode: data.postcode,
                        street: data.street,
                        number: data.number,
                        riskScore: data.riskScore,
                        
                    }
                })
                setAssets(arr)
            }
        })
    }

    useEffect(() => {
        updateAssets()

        const intervalId = setInterval (() => {
            updateAssets()
        }, 2500)

        return () => clearInterval(intervalId)
    }, [])

    const handleRemove = (uaid: string) => {
        removeAsset(uaid).then(() => {
            // refresh asset list
            updateAssets()

        }).catch(
            // TODO handle any errors
        )
    }
    
    return (
        <Table>
            <TableCaption>A list of your assets.</TableCaption>
            <TableHeader>
                <TableRow>
                    <TableHead>Asset</TableHead>
                    <TableHead>Risk Category</TableHead>
                    <TableHead>Risk Score</TableHead>
                    <TableHead>Risk Frequency</TableHead>
                    <TableHead></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {assets.map((asset) => (
                <TableRow key={asset.UAID}>
                        <TableCell className="font-medium capitalize">{asset.street ? asset.street : 'Unknown Address'} {asset.number} - {asset.place ? asset.place : `Unknown Place`}</TableCell>
                        <TableCell>
                            <AssetRiskCategory score={asset.riskScore } />
                        </TableCell>
                        <TableCell>
                            <AssetRiskScore score={asset.riskScore} />
                        </TableCell>
                        <TableCell>
                            <AssetRiskFrequency score={asset.riskScore} />
                        </TableCell>
                        <TableCell>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                    <span className="sr-only">Open menu</span>
                                    <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                    <DropdownMenuItem onClick={() => handleRemove(asset.UAID)}>
                                    Remove
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableCell>
                </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}