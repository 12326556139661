import { AddAsset } from "@/actions/assets/addasset"
import { Button } from "@/components/ui/button"
import { assetInterface } from "@/interfaces"
import { AssetRiskScore } from "@/components/custom/assetriskscore"
import { Activity, BarChart, Flame } from "lucide-react"
import { AssetRiskCategory } from "@/components/custom/assetriskcategory"
import { AssetRiskFrequency } from "@/components/custom/assetriskfrequency"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"


interface AssetPopupProps extends React.HTMLAttributes<HTMLElement> {
    setFocusedOnAsset: Function
    assetInFocus: assetInterface
}

export const AssetPopup = ({setFocusedOnAsset, assetInFocus, ...props}: AssetPopupProps) => {
    const handleAddingAsset = () => {
        // trigger adding asset
        let assetName = ''

        AddAsset(assetName, assetInFocus.lat, assetInFocus.lon, assetInFocus.country, assetInFocus.place, assetInFocus.postcode, assetInFocus.street, assetInFocus.number).then(async (res) => {
            // if res is good, update asset
            // #TODO: trigger updating front-end asset list? maybe even already fill in risk score based on front-end geojson data?
          }).catch(
             // give user feedback if asset adding went wrong?
        )

        setFocusedOnAsset(false)
    }

    const closePopup = () => {
        setFocusedOnAsset(false)
    }
    
    return (
        <div className="flex flex-col bg-secondary h-full w-80 lg:w-96">
            <div className="px-5 my-3 w-full">
                <p className="text-xl font-bold">Asset in {assetInFocus.place}</p>
                <p className="text-sm capitalize text-muted-foreground">{assetInFocus.street} {assetInFocus.number}</p>
                <div className="mt-5">
                {assetInFocus.UAID ? 
                    <div>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="flex flex-row text-base cursor-default"> 
                                        <Flame className="w-4 mr-1"/>
                                        <AssetRiskCategory score={assetInFocus.riskScore} />
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent className="w-56 relative right-20 bg-secondary text-primary">
                                    <p>The wildfire risk category ranges from low to high and reflects the underlying score.</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        
                        <div className="flex flex-row mt-0.5">
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div className="flex flex-row text-xs text-muted-foreground cursor-default">
                                            <BarChart className="w-4 h-3 mr-2 mt-1"/>
                                            <AssetRiskScore score={assetInFocus.riskScore} />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent className="w-56 relative right-20 bg-secondary text-primary">
                                        <p>The wildfire risk score is defined by multiple factors, including climate weather models and vegetation.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div className="ml-4 flex flex-row text-xs text-muted-foreground cursor-default">
                                            <Activity className="w-4 h-3 mr-2 mt-1" />
                                            <AssetRiskFrequency score={assetInFocus.riskScore} />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent className="w-56 relative right-20 bg-secondary text-primary">
                                        <p>The wildfire frequency estimates how often an extreme wildfire takes place in this grid cell.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    </div>
                    :
                    null
                }
                </div>
            </div>
            <div className="m-5 justify-between flex flex-row">
                <div className="w-28">
                    <p className="text-xs text-muted-foreground">{assetInFocus.postcode}</p>
                    <p className="text-xs text-muted-foreground">{assetInFocus.country}</p>
                </div>
                {assetInFocus.UAID ? 
                    null
                    :
                    <Button onClick={() => handleAddingAsset()} className='h-7 w-28 bg-primary hover:bg-secondary hover:text-primary hover:border-primary hover:border'>Add Asset</Button>
                }
            </div> 
            <div onClick={() => closePopup()} className="absolute top-0 right-0 mt-3.5 mr-5 cursor-pointer">
                <p className="text-xs">Close</p>    
            </div>         
        </div>
    )
}