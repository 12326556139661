// importing libraries
import { Link } from 'react-router-dom';

// importing components
import { Button } from "@/components/ui/button"

import { assetInterface } from "@/interfaces";
import { AssetSummary } from "../assetsummary";

interface AssetBoxProps extends React.HTMLAttributes<HTMLElement> {
    focusedOnAsset: boolean,
    setFocusedOnAsset: Function,
    assetInFocus: assetInterface,
    setAssetInFocus: Function
}

export const AssetBox = ({focusedOnAsset, setFocusedOnAsset, assetInFocus, setAssetInFocus, ...props}: AssetBoxProps) => {
    
    return (
        <div className="flex flex-col justify-between bg-secondary h-full">
            <div className="mt-6 px-3 lg:px-5 w-full">
                <p className="text-base font-medium px-2 mb-5">Assets</p>
                <AssetSummary focusedOnAsset={focusedOnAsset} setFocusedOnAsset={setFocusedOnAsset} assetInFocus={assetInFocus} setAssetInFocus={setAssetInFocus}/>
            </div>
                <Link to='/app/assets/' className="mb-10 justify-around flex flex-row">
                    <Button className='h-7 w-40 bg-primary hover:bg-secondary hover:text-primary hover:border-primary hover:border'>Manage Assets</Button>
                </Link>
        </div>
    )
}