// import libs
import { useState } from "react"

// import components
import { AssetList } from "@/components/custom/assetlist"
import { NewAsset } from "@/components/custom/assetbox/newasset"
import { Button } from "@/components/ui/button"

const Assets = () => {
    const [newAssetOpen, setNewAssetOpen] = useState<boolean>(false)

    return (
        <div className="w-full h-full px-10 bg-secondary">
            <div className='flex flex-row w-full justify-between pt-7 pb-5'>
                <p className='text-lg font-medium'>Assets</p>
                { !newAssetOpen ?
                    <Button onClick={() => setNewAssetOpen(true)} className='mr-4 h-7 w-16 bg-primary hover:bg-secondary hover:text-primary hover:border-primary hover:border'>Add</Button>
                    :
                    <Button onClick={() => setNewAssetOpen(false)} className='mr-4 h-7 w-16 bg-primary hover:bg-secondary hover:text-primary hover:border-primary hover:border'>Cancel</Button>
                }    
            </div>
            <div>
                {!newAssetOpen ?
                    <AssetList/>
                    :
                    <NewAsset setNewAssetOpen={setNewAssetOpen} />
                }
            </div>
        </div>
    )
}

export default Assets