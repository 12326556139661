// import modules
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword, connectAuthEmulator } from 'firebase/auth'

// import views


// import components
import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Avatar, AvatarImage } from '@/components/ui/avatar'

import logo from '@/assets/icon.png'

const CreateAccount = () => {
    const navigate = useNavigate()

    // see zod documentation and shadcn form docs
    const formSchema = z.object({
        email: z.string(),
        password: z.string(),
    })

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          email: "",
          password: "",
        },
    })

    const [formMessage, setFormMessage] = useState<String>(" ")
  
    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        console.log(values)
        const newEmail = values.email
        const newPassword = values.password

        const auth = getAuth()

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, newEmail, newPassword)
            console.log(userCredential.user)
            if(userCredential.user){
                navigate("/app/")
           }
        }
        catch(error) {
            console.log(error)
            setFormMessage("Password should be at least 6 characters.")
        }
    }

    return(
        <div className='flex flex-col lg:flex-row'>
            <div className='flex flex-row justify-around lg:hidden'>
                <a href="https://www.terragrid.ai">
                    <Avatar className="w-10 mt-10 md:mt-20">
                        <AvatarImage src={logo} />
                    </Avatar>
                </a>
            </div>
            <div className='hidden lg:flex lg:flex-col lg:justify-between lg:w-1/2 lg:bg-accent lg:h-screen'> 
                <a href="https://www.terragrid.ai">
                    <div className='flex flex-row m-10'>  
                        <Avatar className="w-10 mr-4 w-10">
                            <AvatarImage src={logo} />
                        </Avatar>
                        <span className='hidden lg:inline text-xl font-bold text-white pt-1'>TerraGrid</span>
                    </div>
                </a>
                <div className='hidden lg:block flex flex-row'>
                </div>
                {/* <div>
                    <p className='text-xl text-white m-10 italic'>"quote"</p>
                </div> */}
            </div>
            <div className="flex flex-row justify-around lg:w-1/2">
                <div className="flex flex-col mt-10 md:mt-20 lg:mt-44 w-72">
                    <p className="text-3xl font-medium">Create an account</p>
                    <p className='text-sm mb-5 mt-1 pl-0.5'>Create an account with your email and a secure password of at least 6 characters</p>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                            <FormMessage>{formMessage}</FormMessage>
                            <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input type="email" {...field} />
                                </FormControl>
                                <FormMessage />
                                </FormItem>
                            )}
                            />
                            <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem className='space-y-0'>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input type="password" {...field} />
                                </FormControl>
                                <FormMessage />
                                </FormItem>
                            )}
                            />
                            <FormDescription>
                                Already registered? <NavLink className="underline" to="/login/">Log in here</NavLink>
                            </FormDescription>
                            <Button className="w-full bg-accent active:bg-accent
                            " type="submit">Sign Up</Button>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount