// importing libraries
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import app from "@/firebase-config"
import { getAuth } from "firebase/auth"

export const getAssets = async () => {
    // define user

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    // define authorization
    if(uuid === undefined){
        // TODO: organizations check 
        // TODO: noisier
        return 
    }

    // get from database list of assets
    const assets = collection(db, 'assets')
    const q = query(assets, where('uuid', '==', uuid))

    return await getDocs(q).then((res) => {
        console.log(res)
        const ul = res.docs
        return ul
    }).catch(
        e => {
            console.log(e)
        }
    )
}
