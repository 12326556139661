// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "terragrid-c2de7.firebaseapp.com",
  projectId: "terragrid-c2de7",
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: "261620959532",
  appId: "1:261620959532:web:b1e49b10336972ca310489"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase
export default app
