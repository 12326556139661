interface AssetRiskFrequencyProps extends React.HTMLAttributes<HTMLElement> {
    score: string
}

export const AssetRiskFrequency = ({score}: AssetRiskFrequencyProps) => {
      const numericScore = parseFloat(score)

      switch(true) {
        case numericScore === -1:
          return (<span>Not available</span>)
        case isNaN(numericScore):
          return (<span>Loading...</span>)
        case numericScore < 5:
          return (<span>Never</span>)
        case numericScore < 20:
          return (<span>1:50 yrs</span>)
        case numericScore >= 20 && numericScore < 40:
          return (<span>1:25 yrs</span>)
        case numericScore >= 40 && numericScore < 60:
          return (<span>1:10 yrs</span>)
        case numericScore >= 60 && numericScore < 75:
          return (<span>1:5 yrs</span>)
        case numericScore >= 75:
          return (<span>Every year</span>)
        default:
          return (<span>Not Available</span>)
      }
}