interface AssetRiskCategoryProps extends React.HTMLAttributes<HTMLElement> {
    score: string
}

export const AssetRiskCategory = ({score}: AssetRiskCategoryProps) => {
      const numericScore = parseFloat(score)

      switch(true) {
        case numericScore === -1:
          return (<span>Not available</span>)
        case isNaN(numericScore):
          return (<span>Loading...</span>)
        case numericScore < 20:
          return (<span>Low</span>)
        case numericScore >= 20 && numericScore < 60:
          return (<span>Medium</span>)
        case numericScore >= 60:
          return (<span>High</span>)
        default:
          return (<span>Not Available</span>)
      }
}