// importing libraries
import { addDoc, collection, getFirestore } from "firebase/firestore"
import app from "@/firebase-config"
import { getAuth } from "firebase/auth"
import { v4 as uuidv4 } from 'uuid';
import { addRiskScore } from "./addriskscore";

export const AddAsset = async (name: string, lat: string, lon: string, country: string, place: string, postcode: string, street: string, number: string) => {
    // define user

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    // define authorization
    if(uuid === undefined){
        // TODO: organizations check 
        // TODO: noisier
        return 
    }

    // generate asset ID
    const uaid = uuidv4();

    // create data format to post to database
    const createdDate = new Date().getTime()
    const lastModifiedDate = createdDate
    const riskScore = ""
    
    let newAsset = { uaid, uuid, name, lat, lon, createdDate, lastModifiedDate, country, place, postcode, street, number, riskScore}
    
    // store in database
    const assets = collection(db, "assets")
    await addDoc(assets, newAsset).then((res) => {
        console.log('Asset added to database:', res)
    }).catch(
        e => {
            console.log(e)
        }
    )  
    
    // async trigger to addRiskScore based on generic_map layer
    addRiskScore(uaid, 'generic_map')
    return null
}

    
