// importing libraries
import { collection, deleteDoc, getDocs, getFirestore, query, where } from "firebase/firestore"
import app from "@/firebase-config"
import { getAuth } from "firebase/auth"

export const removeAsset = async (uaid: string) => {
    // define user

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    // define authorization
    if(uuid === undefined){
        // TODO: organizations check 
        // TODO: noisier
        return 
    }

    // get the document ref from db
    const assets = collection(db, 'assets')
    const q = query(assets, where('uaid', '==', uaid))

    getDocs(q)
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) =>{
                const docRef = doc.ref
                deleteDoc(docRef)
                    .then(() => {
                        console.log('Asset successfully deleted!')
                    })
                    .catch((error) => {
                        console.error('Error deleting document:', error)
                    })
            })
        }).catch((error) => {
            console.error('Error getting documents before deleting:', error)
        })
}
