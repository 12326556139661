import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid'
import logo from '@/assets/icon.png'

interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
    navbarOpen: boolean,
    setNavbarOpen: any 
}


export const Header = ({navbarOpen, setNavbarOpen, ...props}: HeaderProps) => {

    return (
        <div className="my-5 ml-3 flex flex-row justify-between">
            {navbarOpen ? 
                <Avatar className="w-7 h-7">
                    <AvatarImage src={logo} />
                    <AvatarFallback>TG</AvatarFallback>
                </Avatar>
                :
                ''
            }
            {navbarOpen ? <ChevronDoubleLeftIcon onClick={() => setNavbarOpen(false)} className="cursor-pointer w-5"/> : <ChevronDoubleRightIcon onClick={() => setNavbarOpen(true)} className="cursor-pointer w-5 ml-1"/>}
        </div>
    )
}