import { getFunctions, httpsCallable } from "firebase/functions"
import app from "@/firebase-config"

export const addRiskScore = async (uaid: string, mapID: string) => {
    // front end caller to trigger adding a risk score
    const functions = getFunctions(app, 'europe-west2')
    const riskScoringAPI = httpsCallable(functions, `updateRiskScore`)
    console.log('trying to trigger asset risk scoring')
    // can be called by different functions, should be a cloud function (e.g. cloud function called when single asset is added, when .csv adds multiple assets and so on, cloud function can also be called by backend) 
    riskScoringAPI({ params: {uaid: uaid, mapID: mapID}})
        .then((res) => {
            console.log('asset risk scoring performed')
        })
        .catch((e) => {
            console.log(e)
        })
}