import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react"
import "./index.css"

import { NavLink } from 'react-router-dom'

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
    navbarOpen: boolean,
    items: {
      title: string,
      href: string,
      icon: ReactElement
    }[]
  }

export const NavList = ({items, navbarOpen, ...props}: SidebarNavProps) => (
    <>
        <nav
        className="flex flex-col"
        {...props}
        >
        {items.map((item) => (
            <NavLink to={item.href} key={item.href} className="flex flex-row px-4 py-3 text-primary hover:bg-secondary">
                <div className="w-5 mr-3 block">
                    {item.icon}
                </div>
                <div>
                    <p className="mt-0.5 text-sm">{navbarOpen ? item.title : null}</p>
                </div>
            </NavLink>
        ))}
        </nav>
    </>
)