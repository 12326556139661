// importing libraries
import * as z from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"

// importing components
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

// see zod documentation and shadcn form docs
const formSchema = z.object({
  name: z.string(),
  lat: z.string(),
  lng: z.string(),
})

interface NewAssetProps extends React.HTMLAttributes<HTMLElement> {
    setNewAssetOpen: Function
}

export const NewAsset = ({setNewAssetOpen}: NewAssetProps) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          name: "",
          lat: "",
          lng: "",
        },
      })
     
      const onSubmit = (values: z.infer<typeof formSchema>) => {
        setNewAssetOpen(false)
        
        // AddAsset(values.name, values.lat, values.lng).then(async (res) => {
        //   // if res is good, update asset
        //   // #TODO: trigger updating front-end asset list? maybe even already fill in risk score based on front-end geojson data?
        // }).catch(
        //    // give user feedback if asset adding went wrong?
        // )
        
        console.log(values)
      }

      return (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="(e.g.) Asset 1" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lat"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Latitude</FormLabel>
                  <FormControl>
                    <Input placeholder="(e.g.) 40.05867" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lng"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Longitude</FormLabel>
                  <FormControl>
                    <Input placeholder="(e.g.) 20.03267" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormDescription>
                These are the Latitude and Longitude of the asset you would like to add.
            </FormDescription>
            <Button type="submit">Add Asset</Button>
          </form>
        </Form>
      )
}